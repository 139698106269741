import '@/styles/globals.css';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import type { AppProps } from 'next/app';

const firebaseConfig = {
  apiKey: "AIzaSyC_Ek9-x6UmQwPl4iUwtrJ1rkmGhHMgZHg",
  authDomain: "poker-hand-replayer.firebaseapp.com",
  projectId: "poker-hand-replayer",
  storageBucket: "poker-hand-replayer.appspot.com",
  messagingSenderId: "931340049255",
  appId: "1:931340049255:web:3a91eac0e21340307c2dc2",
  measurementId: "G-6NVW0DP6KQ"
};


// Initialize Firebase only if it hasn't been initialized yet
export const app = initializeApp(firebaseConfig)

// Get Firestore instance
export const db = getFirestore(app);

// Get Authentication instance
export const auth = getAuth(app);


export default function App({ Component, pageProps }: AppProps) {

  return <Component {...pageProps} />
}
